<template>
  <div>
    <b-button v-b-modal.modal-prevent-closing>
      Open Modal
    </b-button>

    <b-modal
      id="modal-refuse-to-sign"
      ref="modal"
      title="Từ chối ký"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Lý do"
          label-for="name-input"
          invalid-feedback="Lý do bắt buộc nhập"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="cancelReason"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'

import router from '@/router'
import { getUserData } from '@/api/auth/utils'

import useErrorNotiHandle from '../error-noti-list/useErrorNotiHandle'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
  },

  props: {
    errorNotiId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      cancelReason: '',
      nameState: null,
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      const param = {
        creator: getUserData().employeeData.id,
        cancelReason: this.cancelReason,
      }
      this.$nextTick(() => {
        this.refuseToSign(param, this.errorNotiId)
        this.$bvModal.hide('modal-refuse-to-sign')
        router.push({ name: 'apps-error-noti-list' })
      })
    },
  },

  setup() {
    const {
      refuseToSign,
    } = useErrorNotiHandle()

    return {
      refuseToSign,
    }
  },
}
</script>
