<template>
  <section>
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-50"
      class="border"
    >
      <template #header>
        <div>
          <h5 class="m-0 py-75">
            {{ $t('invoice.info') }}
          </h5>
        </div>
      </template>

      <b-card-body class="p-0">
        <b-form>
          <b-row class="mt-1">
            <!-- ANCHOR Invoice No -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="Noti No"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.no"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="Status"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.status"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="Type"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.type"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="TA Accept No"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.taAcceptDate"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="TA Noti No"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.taAcceptNo"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="TA Accept Date"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.taAcceptDate"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="font-weight-bold"
                label="TA Noti Date"
              >
                <b-form-input
                  disabled
                  :value="errorNotiData.taAcceptDate"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import {
  getDate,
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
  },

  props: {
    errorNotiData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      getDate,

    }
  },
}
</script>
