var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-prevent-closing",
      modifiers: {
        "modal-prevent-closing": true
      }
    }]
  }, [_vm._v(" Open Modal ")]), _c('b-modal', {
    ref: "modal",
    attrs: {
      "id": "modal-refuse-to-sign",
      "title": "Từ chối ký"
    },
    on: {
      "show": _vm.resetModal,
      "hidden": _vm.resetModal,
      "ok": _vm.handleOk
    }
  }, [_c('form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSubmit($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lý do",
      "label-for": "name-input",
      "invalid-feedback": "Lý do bắt buộc nhập",
      "state": _vm.nameState
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name-input",
      "state": _vm.nameState,
      "required": ""
    },
    model: {
      value: _vm.cancelReason,
      callback: function callback($$v) {
        _vm.cancelReason = $$v;
      },
      expression: "cancelReason"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }