<template>
  <section>
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-50"
      body-class="p-0"
      class="border"
    >
      <template #header>
        <div>
          <h5 class="m-0 py-75">
            {{ $t('invoice.history') }}
          </h5>
        </div>
      </template>

      {{ historyData }}

      <b-table
        ref="refInvoiceListTable"
        style="max-height: 50vh; margin-bottom: 0; padding: 1em;"
        sticky-header
        responsive
        show-empty
        bordered
        primary-key="id"
        :items="historyData"
        :fields="tableColumns"
        :empty-text="$t('noRecordFund')"
      >
        <!-- ANCHOR Table Header -->
        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column.key})`]="{ label }"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`invoice.historyColumns.${label}`) }}
          </span>
        </template>

        <!-- ANCHOR Style for column(s)) -->
        <template #table-colgroup="{ fields }">
          <col
            v-for="field in fields"
            :key="field.key"
            :style="field.style"
          >
        </template>

        <!-- ANCHOR Column orderNo -->
        <template #cell(orderNo)="{ index }">
          {{ index + 1 }}
        </template>

        <!-- ANCHOR Column date -->
        <template #cell(date)="{ item }">
          {{ dateTime(item.date) }}
        </template>

        <!-- ANCHOR Column action -->
        <template #cell(action)="{ item }">
          <b-badge
            variant="info"
            class="badge-glow"
          >
            {{ $t(`invoice.historyActions.${item.action}`) }}
          </b-badge>
        </template>

        <!-- ANCHOR Column executor -->
        <template #cell(executor)="{ item }">
          {{ (item.executor) }}
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
} from 'bootstrap-vue'

import {
  INVOICE_ACTIONS_HISTORY_TABLE_COLUMNS as tableColumns,
} from '@/constants/invoice'

import {
  dateTime,
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
  },

  props: {
    historyData: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      // from imports
      tableColumns,
      dateTime,
    }
  },
}
</script>
