var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "border",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', [_c('h5', {
          staticClass: "m-0 py-75"
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.info')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "p-0"
  }, [_c('b-form', [_c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "Noti No"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.no
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "Status"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.status
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.type
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "TA Accept No"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.taAcceptDate
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "TA Noti No"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.taAcceptNo
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "TA Accept Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.taAcceptDate
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": "TA Noti Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.errorNotiData.taAcceptDate
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }