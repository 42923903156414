var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "border",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', [_c('h5', {
          staticClass: "m-0 py-75"
        }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.description')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticStyle: {
      "margin-bottom": "0",
      "padding": "1em"
    },
    attrs: {
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-invoice-edit-gs border-bottom",
      "items": _vm.descriptionData,
      "fields": _vm.tableColumnsAdd,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsAdd, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('span', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: column.key != 'action',
              expression: "column.key != 'action'"
            }],
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("errorNoti.gsColumns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "cell(orderNo)",
      fn: function fn(_ref2) {
        var index = _ref2.index;
        return [_c('div', {
          staticClass: "w-100 text-center pt-50"
        }, [_vm._v(" " + _vm._s(index + 1) + " ")])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }