var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "border",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-50",
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', [_c('h5', {
          staticClass: "m-0 py-75"
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.history')) + " ")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" " + _vm._s(_vm.historyData) + " "), _c('b-table', {
    ref: "refInvoiceListTable",
    staticStyle: {
      "max-height": "50vh",
      "margin-bottom": "0",
      "padding": "1em"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "bordered": "",
      "primary-key": "id",
      "items": _vm.historyData,
      "fields": _vm.tableColumns,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.historyColumns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "table-colgroup",
      fn: function fn(_ref2) {
        var fields = _ref2.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: field.style
          });
        });
      }
    }, {
      key: "cell(orderNo)",
      fn: function fn(_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(date)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.dateTime(item.date)) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.historyActions.".concat(item.action))) + " ")])];
      }
    }, {
      key: "cell(executor)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.executor) + " ")];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }