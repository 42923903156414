<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <component :is="errorNotiData === undefined ? 'div' : 'b-card'">
      <!-- ANCHOR Alert: No Item Found  -->
      <b-alert
        variant="danger"
        :show="errorNotiData === undefined"
      >
        <h4 class="alert-heading">
          {{ $t('errorNoti.errFetchNoti') }}
        </h4>
        <div class="alert-body">
          {{ $t('errorNoti.notFundNotiById_1') }}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-error-noti-list'}"
          >
            {{ $t('errorNoti.list') }}
          </b-link>
          {{ $t('errorNoti.notFundNotiById_2') }}
        </div>
      </b-alert>
      <template v-if="errorNotiData">
        <b-card-title class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="flex-shrink-0">
            <feather-icon
              icon="BoxIcon"
              size="24"
              class="mx-50"
            />
            <span>{{ $t('errorNoti.pageTitle.detail') }}</span>
          </div>

          <!-- ANCHOR Action Buttons  -->
          <div class="d-flex justify-content-center justify-content-xl-end align-items-center flex-grow-1 flex-wrap">
            <b-button
              variant="primary"
              class="ml-1 mt-50"
            >
              {{ $t('errorNoti.btn.previewInDetailPage') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 mt-50"
              :to="{ name: 'apps-error-noti-update', params: { id: errorNotiData.id } }"
            >
              {{ $t('invoice.btn.update') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 mt-50"
            >
              {{ $t('errorNoti.btn.sendToSign') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 mt-50"
            >
              {{ $t('invoice.btn.sign') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 mt-50"
              @click="confirmRefuseToSign(errorNotiData.id)"
            >
              {{ $t('invoice.btn.refuseSign') }}
            </b-button>

            <b-button
              variant="primary"
              class="ml-1 mt-50"
              @click="confirmDelete(errorNotiData.id)"
            >
              {{ $t('errorNoti.btn.delete') }}
            </b-button>
          </div>
        </b-card-title>

        <!-- ANCHOR Section Components  -->
        <ErrorNotiDetailInfo
          ref="refErrorNotiDetailInfo"
          :error-noti-data="errorNotiData"
        />
        <!-- <ErrorNotiActionsHistory
          :history-data="errorNotiData"
        /> -->

        <ErrorNotiDescription
          :description-data="errorNotiData.invoices"
        />

        <!--  ANCHOR Button Back -->
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            :to="{ name: 'apps-error-noti-list' }"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('back') }}</span>
          </b-button>
        </div>

        <RefuseToSignModal :error-noti-id="errorNotiData.id" />

      </template>
    </component>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BLink, BCard, BCardTitle, BButton,
} from 'bootstrap-vue'
import {
  ref, onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import {
  ERROR_NOTI_APP_STORE_MODULE_NAME as ERROR_NOTI_STORE,
} from '@/constants/errorNoti'

import RefuseToSignModal from './RefuseToSignModal.vue'
import errorNotiStoreModule from '../errorNotiStoreModule'
import ErrorNotiDetailInfo from './ErrorNotiDetailInfo.vue'
import ErrorNotiActionsHistory from './ErrorNotiActionsHistory.vue'
import ErrorNotiDescription from './ErrorNotiDescription.vue'
import useErrorNotiHandle from '../error-noti-list/useErrorNotiHandle'

// import {
//   showPopupPreviewInvoice,
// } from '../invoice-popup/useInvoicePopupPreview'
// import {
//   getInvoiceDetailForSendEmail,
//   showPopupInvoiceSendEmail,
// } from '../invoice-popup/useInvoiceSendEmail'
// import {
//   actions,
// } from '../useInvoice'

export default {
  components: {
    BOverlay,
    BAlert,
    BLink,
    BCard,
    BCardTitle,
    BButton,

    ErrorNotiDetailInfo,
    ErrorNotiActionsHistory,
    ErrorNotiDescription,
    RefuseToSignModal,

    // InvoicePopupCancel: () => import('../invoice-popup/InvoicePopupCancel.vue'),
    // InvoicePopupPreview: () => import('../invoice-popup/InvoicePopupPreview.vue'),
    // InvoicePopupSendEmail: () => import('../invoice-popup/InvoicePopupSendEmail.vue'),
  },

  computed: {
    roleMama() {
      return this.$store.state.userStore.roleMama // true: role Mama, false: role Agency
    },
  },

  setup() {
    // Register module
    if (!store.hasModule(ERROR_NOTI_STORE)) store.registerModule(ERROR_NOTI_STORE, errorNotiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_NOTI_STORE)) store.unregisterModule(ERROR_NOTI_STORE)
    })

    const {
      deleteErrorNoti,
      refuseToSign,
    } = useErrorNotiHandle()

    const loading = ref(true)
    const errorNotiData = ref(null)
    const historyData = ref([])
    const invoiceDetailForSendEmail = ref([])

    const fetchInvoiceData = () => store.dispatch('app-error-noti/getErrorNotiById', { id: router.currentRoute.params.id })
      .then(response => {
        errorNotiData.value = response.data
        // historyData.value = response.data
        // invoiceDetailForSendEmail.value = getInvoiceDetailForSendEmail([errorNotiData.value])
      })
      .catch(error => {
        if (error) {
          errorNotiData.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    fetchInvoiceData()

    // const type = computed(() => errorNotiData.value?.invoiceType)
    // const status = computed(() => errorNotiData.value?.status)
    // const hasData = computed(() => type.value && status.value)

    // const canUpdateOrSendToSign = computed(() => (hasData.value ? actions.canUpdateOrSendToSign(status.value) : false))
    // const canSignOrRefuseSign = computed(() => (hasData.value ? actions.canSignOrRefuseSign(status.value) : false))

    // Sign Invoice & Send Email
    const useWhenSignInvoice = ref(false)
    const autoSendEmail = ref(false)

    function confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteErrorNoti(id)
            router.push({ name: 'apps-error-noti-list' })
          }
        })
    }

    function confirmRefuseToSign() {
      this.$bvModal.show('modal-refuse-to-sign')
    }

    return {
      loading,
      errorNotiData,
      historyData,

      // actions for invoice detail
      // canUpdateOrSendToSign,
      // canSignOrRefuseSign,

      // preview invoice
      // showPopupPreviewInvoice,

      // Sign Invoice & Send Email
      invoiceDetailForSendEmail,
      useWhenSignInvoice,
      autoSendEmail,
      // showPopupInvoiceSendEmail,

      confirmDelete,
      deleteErrorNoti,

      confirmRefuseToSign,
      refuseToSign,
    }
  },
}
</script>
