<template>
  <section>
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-50"
      class="border"
    >
      <template #header>
        <div>
          <h5 class="m-0 py-75">
            {{ $t('errorNoti.description') }}
          </h5>
        </div>
      </template>
      <b-table
        style="margin-bottom: 0; padding: 1em;"
        responsive
        show-empty
        primary-key="id"
        table-class="table-invoice-edit-gs border-bottom"
        :items="descriptionData"
        :fields="tableColumnsAdd"
        :empty-text="$t('noRecordFund')"
      >
        <!-- ANCHOR Table Header -->
        <template
          v-for="column in tableColumnsAdd"
          v-slot:[`head(${column.key})`]="{ label }"
        >
          <span
            v-show="column.key != 'action'"
            :key="column.label"
            class="text-dark text-nowrap text-center"
          >
            {{ $t(`errorNoti.gsColumns.${label}`) }}
          </span>
        </template>

        <!-- ANCHOR Column orderNo -->
        <template #cell(orderNo)="{ index }">
          <div
            class="w-100 text-center pt-50"
          >
            {{ index + 1 }}
          </div>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BCard,
} from 'bootstrap-vue'

import useErrorNotiAddHandle from '../error-noti-add/useErrorNotiAddHandle'

export default {
  components: {
    BTable,
    BCard,
  },
  props: {
    descriptionData: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const {
      tableColumnsAdd,
    } = useErrorNotiAddHandle()

    return {
      tableColumnsAdd,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
