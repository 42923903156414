var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c(_vm.errorNotiData === undefined ? 'div' : 'b-card', {
    tag: "component"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.errorNotiData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.errFetchNoti')) + " ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.notFundNotiById_1')) + " "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-error-noti-list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.list')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('errorNoti.notFundNotiById_2')) + " ")], 1)]), _vm.errorNotiData ? [_c('b-card-title', {
    staticClass: "d-flex justify-content-between align-items-center flex-wrap"
  }, [_c('div', {
    staticClass: "flex-shrink-0"
  }, [_c('feather-icon', {
    staticClass: "mx-50",
    attrs: {
      "icon": "BoxIcon",
      "size": "24"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('errorNoti.pageTitle.detail')))])], 1), _c('div', {
    staticClass: "d-flex justify-content-center justify-content-xl-end align-items-center flex-grow-1 flex-wrap"
  }, [_c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.previewInDetailPage')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'apps-error-noti-update',
        params: {
          id: _vm.errorNotiData.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.update')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.sendToSign')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sign')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmRefuseToSign(_vm.errorNotiData.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.refuseSign')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmDelete(_vm.errorNotiData.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.delete')) + " ")])], 1)]), _c('ErrorNotiDetailInfo', {
    ref: "refErrorNotiDetailInfo",
    attrs: {
      "error-noti-data": _vm.errorNotiData
    }
  }), _c('ErrorNotiDescription', {
    attrs: {
      "description-data": _vm.errorNotiData.invoices
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger",
      "to": {
        name: 'apps-error-noti-list'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RotateCcwIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('back')))])], 1)], 1), _c('RefuseToSignModal', {
    attrs: {
      "error-noti-id": _vm.errorNotiData.id
    }
  })] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }